<template>
  <div id="deposit-modal">
    <div class="deposit-modal-wrapper">
      <img
        src="../../assets/images/close-icon.svg"
        alt=""
        class="close-icon"
        @click.prevent="closeModal"
      />
      <div class="deposit-content-wrapper">
        <div class="header">
          واریز
        </div>
        <div class="content">
          موجودی کیف پول به شما این امکان را می دهد تا در مواردی که درگاه بانکی
          مشکل دارد سریعتر خرید کنید و هر بار نیاز به وارد کردن اطلاعات حساب
          بانکی نباشد.
        </div>
        <div class="deposit-amount-form">
          <div class="label">
            مبلغ واریز
          </div>
          <div class="input-wrapper">
            <label>
              <input
                type="text"
                :class="{ error: errorMassage }"
                class="input"
                @keypress="isNumber($event)"
                v-model="amount"
                @input="
                  amount = Number(amount.replace(/,/g, '')).toLocaleString()
                "
              />
            </label>
            <div v-if="errorMassage" class="error-massage">
              {{ this.errorMassage }}
            </div>
          </div>
        </div>
        <div class="deposit-amount-form">
          <div class="label">
            شرح
          </div>
          <div class="input-wrapper">
            <label>
              <textarea name="" class="textarea" v-model="description">
              </textarea>
            </label>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button class="transfer-to-gateway" @click.prevent="goGate">
          انتقال به درگاه پرداخت
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DepositModal",
  data() {
    return {
      amount: null,
      description: null,
      originalAmount: null,
      errorMassage: null
    };
  },
  methods: {
    goGate() {
      if (!this.originalAmount) {
        this.errorMassage = "لطفا مبلغ را وارد نمیایید";
        return false;
      }
      let data = {
        amount: this.originalAmount,
        description: this.description
      };

      axios({
        url: "/panel/wallet/charge",
        method: "POST",
        data: data
      })
        .then(response => {
          if (!response.data.status) {
            console.log(response.data);
            this.$toast.warning(response.data.message);
            return false;
          }
          this.$toast.success(response.data.message);
        })
        .then(error => {
          console.log(error);
        });
      this.$emit("close");
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closeModal() {
      this.$emit("close");
    }
  },
  watch: {
    amount() {
      this.originalAmount = Number(this.amount.replace(/,/g, ""));
    }
  }
};
</script>

<style scoped>
#deposit-modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  /*position: relative;*/
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
}

.deposit-modal-wrapper {
  width: 100%;
  max-width: 700px;
  min-height: 422px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 15px;
}

.close-icon {
  cursor: pointer;
}

.deposit-content-wrapper {
  /*padding: 17px 50px 0 35px;*/
}

.header {
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  color: #121212;
  text-align: center;
  margin-bottom: 15px;
}

.content {
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #121212;
}

.deposit-amount-form {
  margin-top: 10px;
}

.label {
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #121212;
}

.input-wrapper {
  width: 100%;
}

.input {
  width: 100%;
  outline: none;
  border: none;
  background-color: rgba(31, 60, 136, 0.2);
  padding: 5px 10px;
  border-radius: 5px;
}

.textarea {
  width: 100%;
  min-height: 112px;
  outline: none;
  border: none;
  resize: none;
  background-color: rgba(31, 60, 136, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.button-wrapper {
  text-align: center;
  margin-top: 10px;
}

.transfer-to-gateway {
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #fff;
  background: #1f3c88;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 8px 43px;
  cursor: pointer;
}

.error {
  border: 1px solid #ff6b6b !important;
}

.error-massage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 768px) {
  .deposit-content-wrapper {
    padding: 17px 50px 0 35px;
  }

  .header {
    font-size: 20px;
  }

  .content {
    font-size: 16px;
  }

  .label {
    font-size: 16px;
  }

  .transfer-to-gateway {
    font-size: 16px;
  }
}
</style>
