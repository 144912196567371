<template>
  <div id="wallet">
    <loading :can-cancel="true"></loading>
    <DepositModal v-if="showDepositModal" @close="showDepositModal = false" />
    <WithdrawModal
      v-if="showWithdrawModal"
      @close="showWithdrawModal = false"
    />
    <PanelHeader title="کیف پول" />
    <div class="content-container">
      <!--    wallet card section-->
      <div class="wallet-container">
        <div class="wallet-balance-wrapper">
          <div class="wallet-balance-container">
            <div class="wallet-balance__right-section">
              <div class="wallet-balance__title">
                موجودی کیف پول
              </div>
              <div class="wallet-balance__inventory">
                {{ balance ? balance.toLocaleString() : "0"
                }}<span> تومان</span>
              </div>
            </div>
            <div class="wallet-balance__left-section">
              <div class="wallet-balance__deposit-button-container">
                <BtnDeposit @clicked="handleShowDepositeModal" />
              </div>
              <div class="wallet-balance__withdraw-button-container">
                <BtnWithdraw @clicked="handleShowWithdrawModal" />
              </div>
              <div class="wallet-balance__image">
                <img src="../assets/images/wallet-balance-image.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    table section-->
      <section class="table-container">
        <div class="table-section-header">
          تاریخچه ی تراکنش ها
        </div>
        <div class="table-wrapper">
          <TransactionsTable
            :transactions="transactions"
            :balance="balance"
            :user="user"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import BtnDeposit from "@/components/Button/BtnDeposit";
import BtnWithdraw from "@/components/Button/BtnWithdraw";
import DepositModal from "@/components/Modals/DepositModal";
import WithdrawModal from "@/components/Modals/WithdrawModal";
import TransactionsTable from "@/components/Table/TransactionsTable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: {
    WithdrawModal,
    DepositModal,
    TransactionsTable,
    BtnWithdraw,
    BtnDeposit,
    PanelHeader,
    Loading
  },
  data() {
    return {
      showDepositModal: false,
      showWithdrawModal: false,
      user: null,
      balance: null,
      transactions: {}
    };
  },
  methods: {
    handleShowDepositeModal() {
      this.showDepositModal = true;
    },
    handleShowWithdrawModal() {
      this.showWithdrawModal = true;
    },
    getAllTransactions() {
      let loader = this.$loading.show();
      axios("/panel/wallet")
        .then(response => {
          this.user = response.data.user;
          this.transactions = response.data.transactions;
          // console.log('wallet data', this.transactions);
          // this.transactionsData = response.data.transactions;
          this.balance = response.data.balance;
          loader.hide();
        })
        .catch(error => {
          console.log(error);
          loader.hide();
        });
    }
  },
  mounted() {
    this.getAllTransactions();
  }
};
</script>

<style scoped>
#wallet {
  /*width: calc(100% - 250px);*/
  margin-right: auto;
  width: 100%;
}

.content-container {
  height: 100vh;
  margin-bottom: 90px;
}

.wallet-container {
  padding: 0 10px;
  margin-top: 70px;
}

.wallet-balance-container {
  width: 100%;
  min-height: 126px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  padding: 25px 20px;
  flex-wrap: wrap;
}

.wallet-balance__right-section {
  display: flex;
  flex: 1;
  align-items: self-start;
  gap: 20px;
  /*flex-wrap: wrap;*/
  margin-bottom: 20px;
}

.wallet-balance__title {
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.wallet-balance__inventory {
  font-weight: 500;
  font-size: 16px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.wallet-balance__inventory span {
  font-family: "Vazir Medium FD";
}

.wallet-balance__left-section {
  display: flex;
  flex: 1;
  justify-content: space-around;
  /*align-items: flex-end;*/
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column-reverse;
  align-items: center;
}

.wallet-balance__deposit-button-container {
  width: 150px;
  margin-bottom: 10px;
}

.wallet-balance__withdraw-button-container {
  width: 150px;
  margin-bottom: 10px;
}

::v-deep #btn-deposit {
  max-width: 100%;
}

::v-deep #btn-withdraw {
  max-width: 100%;
}

.table-container {
  padding: 0 10px;
  margin-top: 25px;
}

.table-section-header {
  margin-bottom: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Vazir Medium FD";
}

@media (min-width: 540px) {
  .wallet-balance__left-section {
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: flex-end;
    /* flex-wrap: wrap; */
    gap: 20px;
    flex-wrap: unset;
    flex-direction: unset;
  }

  .wallet-balance__inventory {
    font-size: 20px;
  }

  .wallet-balance__title {
    font-size: 16px;
  }
}

@media (min-width: 960px) {
  .wallet-container {
    padding: 0 40px;
    margin-top: 20px;
  }

  .table-container {
    padding: 0 40px;
  }

  .wallet-balance__title {
    font-size: 22px;
  }

  .wallet-balance__inventory {
    font-size: 28px;
  }

  .table-section-header {
    text-align: start;
  }
}

@media (min-width: 1140px) {
  #wallet {
    width: calc(100% - 250px);
  }
}

/*0*/
/*@media (min-width: 540px) {*/
/*  #wallet {*/
/*    width: 100%;*/
/*  }*/

/*  .wallet-container {*/
/*    padding: 0 10px;*/
/*    margin-top: 70px;*/
/*  }*/

/*  .wallet-balance__title {*/
/*    font-size: 16px;*/
/*  }*/

/*  .wallet-balance__inventory {*/
/*    font-size: 20px;*/
/*  }*/

/*  .wallet-balance__right-section {*/
/*    justify-content: center;*/
/*  }*/

/*  .wallet-balance__left-section {*/
/*    gap: 15px;*/
/*  }*/
/*}*/

/*@media (max-width: 960px) {*/
/*  #wallet {*/
/*    width: 100%;*/
/*  }*/

/*  .wallet-container {*/
/*    padding: 0 10px;*/
/*    margin-top: 70px;*/
/*  }*/

/*  .wallet-balance__left-section {*/
/*    flex-wrap: unset;*/
/*    justify-content: space-between;*/
/*  }*/

/*  .wallet-balance__withdraw-button-container {*/
/*    width: auto;*/
/*  }*/

/*  .wallet-balance__deposit-button-container {*/
/*    width: auto;*/
/*  }*/
/*}*/

/*@media (max-width: 720px) {*/
/*  #wallet {*/
/*    width: 100%;*/
/*  }*/

/*  .wallet-container {*/
/*    padding: 0 10px;*/
/*    margin-top: 70px;*/
/*  }*/
/*}*/
</style>
