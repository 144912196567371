<template>
  <div id="transaction-status-modal">
    <div class="transaction-status-wrapper">
      <img
        src="../../assets/images/close-icon.svg"
        alt=""
        class="close-icon"
        @click.prevent="closeModal"
      />
      <div class="transaction-content__wrapper">
        <div class="content__header">
          جزئیات تراکنش
        </div>
        <div class="content">
          <div class="content__column">
            <div class="row">
              <span class="subject">شماره تراکنش: </span
              ><span class="transaction-number">{{
                transactionData.order_number
              }}</span>
            </div>
            <div class="row">
              <span class="subject">نام مشتری:</span>
              <span>{{ " " + user.first_name + " " + user.last_name }}</span>
            </div>
          </div>
          <div class="content__column">
            <div class="row">
              <span class="subject"
                >وضعیت {{ typeHandler[transactionData.type].name }}:</span
              >
              <span
                class="pay-status"
                :style="{ color: statusHandler[transactionData.status].color }"
              >
                {{ statusHandler[transactionData.status].name }}
              </span>
            </div>
            <div class="row">
              <span class="subject">موبایل:</span>
              <span>{{ " " + user.mobile }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button class="transfer-to-gateway" @click.prevent="closeModal">
          متوجه شدم
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionStatusModal",
  props: ["user", "transactionInformation"],
  data() {
    return {
      showModal: true,
      typeHandler: {
        withdraw: { name: "برداشت" },
        deposit: { name: "پرداخت" }
      },
      statusHandler: {
        process: { color: "#FB9224", name: ` در حال بررسی میباشد!` },
        confirm: { color: "#039100", name: "با موفقیت پرداخت شد" },
        reject: { color: "#DC0000", name: "پرداخت ناموفق بود" }
      },
      userData: this.user,
      transactionData: this.transactionInformation
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }
  },
  watch: {
    userData(newValue) {
      this.userData = newValue;
    },
    transactionData(newValue) {
      this.transactionData = newValue;
    }
  }
};
</script>

<style scoped>
#transaction-status-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.transaction-status-wrapper {
  width: 100%;
  max-width: 587px;
  min-height: 252px;
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
}

.close-icon {
  cursor: pointer;
}

.transaction-content__wrapper {
  /*padding: 0 40px;*/
}

.content__header {
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  font-family: "Vazir Medium FD";
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.transaction-number {
  font-size: 16px;
  line-height: 25px;
  color: #1f3c88;
}

.subject {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.pay-status {
  /*color: #039100;*/
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
}

.content__column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.button-wrapper {
  text-align: center;
  margin-top: 30px;
}

.transfer-to-gateway {
  font-weight: normal;
  font-size: 16px;
  /*font-size: 12px;*/
  line-height: 25px;
  color: #fff;
  background: #1f3c88;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 4px 42px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .transaction-content__wrapper {
    padding: 0 15px;
  }
}
</style>
