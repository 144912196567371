<template>
  <div id="sort-table">
    <TransactionStatusModal
      v-if="modal"
      @close="shoModal"
      :user="userInformation"
      :transactionInformation="transactionInformation"
    />
    <table v-if="transactions && transactions.length">
      <tr class="header">
        <th>
          <span>نوع</span>
        </th>
        <th>
          <span>مبلغ سفارش</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          <span>موجودی</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          <span>زمان</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          وضعیت
        </th>
        <th>
          شرح
        </th>
        <th>
          جزئیات
        </th>
      </tr>
      <tr v-for="item in transactions" :key="item.id">
        <td>
          {{ handleType[item.type].name }}
        </td>
        <td>
          <span>
            {{ Number(item.amount).toLocaleString() }}
          </span>
          تومان
        </td>
        <td>
          <span>
            {{ Number(balance).toLocaleString() }}
          </span>
          تومان
        </td>
        <td>
          {{ $G2J(item.created_at_date) }}
        </td>
        <td
          class="green"
          :style="{ color: handleStatus[item.status]['color'] }"
        >
          {{ handleStatus[item.status].name }}
        </td>
        <td>
          {{ item.description }}
        </td>
        <td>
          <img
            @click.prevent="showTransactionInformation(item.id)"
            style="cursor: pointer"
            class="action-image"
            src="../../assets/images/carbon_view.svg"
            alt=""
          />
        </td>
      </tr>
      <tr v-if="!transactions">
        <td>هیچ تراکنشی ثبت نشده است</td>
      </tr>
    </table>

    <table v-if="!transactions.length">
      <tr class="header">
        <th>
          <span>نوع</span>
        </th>
        <th>
          <span>مبلغ سفارش</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          <span>موجودی</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          <span>زمان</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          وضعیت
        </th>
        <th>
          شرح
        </th>
        <th>
          جزئیات
        </th>
      </tr>
      <tr>
        <span>
          هیج تراکنشی ثبت نشده است
        </span>
      </tr>
    </table>
  </div>
</template>
<script>
import TransactionStatusModal from "@/components/Modals/TransactionStatusModal";
import "../../Utility/georgianToJalali";

export default {
  name: "TransactionsTable",
  props: ["transactions", "balance", "user"],
  components: { TransactionStatusModal },
  data() {
    return {
      modal: false,
      transactionInformation: this.transactions,
      userInformation: this.user,
      handleStatus: {
        process: { color: "#FB9224", name: "در حال بررسی" },
        confirm: { color: "#039100", name: "موفق" },
        reject: { color: "#DC0000", name: "ناموفق" }
      },
      handleType: {
        deposit: { name: "واریز" },
        withdraw: { name: "برداشت" }
      }
    };
  },
  watch: {
    // transactionInformation(newValue) {
    //   this.transactionInformation = newValue;
    // },
    // userInformation(newValue) {
    //   this.userInformation = newValue;
    // }
  },
  methods: {
    shoModal() {
      this.modal = false;
    },
    showTransactionInformation(id) {
      this.transactions.find(item => {
        if (item.id === id) {
          this.transactionInformation = item;
          this.userInformation = this.user;
        }
      });
      this.modal = true;
    }
  },
  mounted() {
    // console.log(this.transactionInformation);
  }
};
</script>
<style scoped>
#sort-table {
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: auto;
}

table {
  width: 100%;
  border: none;
  min-width: 1110px;
  border-spacing: 0;
}

th {
  padding: 10px 0;
}

tr {
  /*height: 70px;*/
}

td {
  text-align: center;
  padding: 10px 0;
  /*height: 47px;*/
}

img {
  vertical-align: middle;
}

.header {
  background: rgba(31, 60, 136, 0.1);
  /*height: 45px;*/
}

span {
  vertical-align: middle;
}

.item-image {
  width: 40px;
  height: 40px;
}

.action-image {
  width: 30px;
  height: 30px;
}

.item-name {
  margin-right: 10px;
}

.green {
  color: #039100;
}

.red {
  color: #dc0000;
}
</style>
