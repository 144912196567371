<template>
  <div id="btn-withdraw" @click.prevent.stop="$emit('clicked')">
    <img src="../../assets/images/gg_math-minus.svg" alt="" />
    <div class="text">برداشت</div>
  </div>
</template>
<script>
export default {
  name: "BtnWithdraw",
  data() {
    return {};
  }
};
</script>
<style scoped>
#btn-withdraw {
  min-width: 120px;
  max-width: 120px;
  min-height: 30px;
  max-height: 30px;
  background: #ffffff;
  border: 1px solid #dc0000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.text {
  color: #dc0000;
  margin-right: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>
