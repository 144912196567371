<template>
  <div id="withdraw-modal" v-if="showModal">
    <div class="withdraw-wrapper">
      <img
        src="../../assets/images/close-icon.svg"
        alt=""
        class="close-icon"
        @click.prevent="closeModal"
      />
      <div class="deposit-content-wrapper">
        <div class="header">
          برداشت
        </div>
        <div class="content">
          حداقل مبلغ قابل برداشت 50,000 تومان است.
        </div>
        <div class="deposit-amount-form">
          <div class="label">
            مبلغ برداشت
          </div>
          <div class="input-wrapper">
            <label>
              <input
                type="text"
                class="input"
                ref="amount"
                @keypress="isNumber($event)"
                v-model="amount"
                :class="{ error: errorMassage }"
                @input="
                  amount = Number(amount.replace(/,/g, '')).toLocaleString()
                "
              />
            </label>
            <div v-if="errorMassage" class="error-massage">
              {{ this.errorMassage }}
            </div>
          </div>
        </div>
        <div class="deposit-card-selected">
          <div class="label">
            انتخاب کارت
          </div>
          <BankCardSelect
            :options="bank_card"
            @input="getBankAccount"
            :errorMassage="errorBankCard"
            ref="selector"
          />
        </div>
        <div class="deposit-amount-form">
          <div class="label">
            شرح
          </div>
          <div class="input-wrapper">
            <label>
              <textarea
                name=""
                class="textarea"
                v-model="description"
              ></textarea>
            </label>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button class="transfer-to-gateway" @click.prevent="withdraw">
          برداشت
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BankCardSelect from "@/components/SelectInput/BankCardSelect";
import axios from "axios";

export default {
  name: "WithdrawModal",
  components: { BankCardSelect },
  data() {
    return {
      showModal: true,
      description: null,
      bank_card: [],
      amount: null,
      bank_selected: null,
      originalAmount: null,
      errorMassage: null,
      errorBankCard: ""
    };
  },
  mounted() {
    this.getUserBankCards();
  },
  methods: {
    withdraw() {
      this.errorMassage = null;
      this.errorBankCard = null;
      if (!this.originalAmount) {
        this.$refs.amount.focus();
        this.errorMassage = "لطفا مبلغ را وارد نمیایید";
        return false;
      }

      if (!this.bank_selected) {
        this.errorBankCard = "لطفا کارت بانکی را وارد کنید";
        return false;
      }

      let data = {
        amount: this.originalAmount,
        bank_account_id: this.bank_selected.id,
        description: this.description
      };

      axios({
        url: "/panel/wallet/withdraw",
        method: "POST",
        data: data
      })
        .then(response => {
          if (!response.data.status) {
            this.$emit("close");
            this.$toast.warning(response.data.message);
            return false;
          }
          this.$emit("close");
          this.$toast.success(response.data.message);
          console.log(response.data);
        })
        .catch(error => {
          this.$emit("close");
          console.log(error.response);
        });
    },
    closeModal() {
      this.$emit("close");
    },
    async getUserBankCards() {
      try {
        let response = await axios("/panel/bank-account?status=confirm");
        return (this.bank_card = response.data);
      } catch (error) {
        console.log(error.response);
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getBankAccount(item) {
      this.bank_selected = item;
    }
  },
  watch: {
    amount() {
      this.originalAmount = Number(this.amount.replace(/,/g, ""));
    }
  }
};
</script>

<style scoped>
#withdraw-modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.withdraw-wrapper {
  max-width: 578px;
  width: 100%;
  min-height: 474px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 15px;
}

.close-icon {
  cursor: pointer;
}

.deposit-content-wrapper {
  /*padding: 17px 50px 0 35px;*/
}

.header {
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  color: #121212;
  text-align: center;
  margin-bottom: 15px;
}

.content {
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #121212;
}

.deposit-amount-form {
  margin-top: 10px;
}

.label {
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #121212;
}

.deposit-amount-form {
  margin-top: 10px;
}

.label {
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #121212;
}

.input-wrapper {
  width: 100%;
}

.input {
  width: 100%;
  outline: none;
  border: none;
  background-color: rgba(31, 60, 136, 0.2);
  padding: 5px 10px;
  border-radius: 5px;
}

.textarea {
  width: 100%;
  min-height: 112px;
  outline: none;
  border: none;
  resize: none;
  background-color: rgba(31, 60, 136, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.button-wrapper {
  text-align: center;
  margin-top: 10px;
}

.transfer-to-gateway {
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #fff;
  background: #1f3c88;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 8px 90px;
  cursor: pointer;
}

.deposit-card-selected {
  margin-top: 30px;
}

.error {
  border: 1px solid #ff6b6b !important;
}

.error-massage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 768px) {
  .deposit-content-wrapper {
    padding: 17px 50px 0 35px;
  }

  .header {
    font-size: 20px;
  }

  .content {
    font-size: 16px;
  }

  .label {
    font-size: 16px;
  }

  .transfer-to-gateway {
    font-size: 16px;
  }
}
</style>
